import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/compare-banner"
import ComparisonTable from "../components/comparison-table"
import { Helmet } from "react-helmet"
// import SingleContent from "../components/single-column"
import BadgeContent from "../components/badge-component"
import FreeTrial from '../components/free-trial'
// import Moment from "react-moment";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {
  trialContent,
} from "../lib/home-content"

const bannerContent = {
  height: "auto",
  sectionCompared: "compared",
  title:
    "The result are in. Customers love Billsby's best-in-class subscription billing software",
  text:
    "We're proud that Billsby is some of the highest rated and most affordable subscription billing software around.",
}

const comparisonTableContent = {
  title: "The highest rated subscription billing software",
  description:
    "Compared to our major competitors, Billsby is easier to use, setup and has higher quality support. But don't take our word for it. Independently collected review data from G2 shows how Billsby beats the competition.",
  heading: [
    {
      logo: "billsby_logo_blue.png",
      title: "Billsby",
    },
    {
      logo: require("../images/chargebee.svg"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/chargify.svg"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/recurly.png"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/stripe-billing.png"),
      title: "billwerk",
      svgLogo: true,
    },
  ],
  body: [
    {
      title: "Overall score",
      content: [
        {
          itemContent: "4.8 / 5",
        },
        {
          itemContent: "4.6 / 5",
        },
        {
          itemContent: "4.3 / 5",
        },
        {
          itemContent: "4.0 / 5",
        },
        {
          itemContent: "4.4 / 5",
        },
      ],
    },
    {
      title: "Ease of use",
      content: [
        {
          itemContent: "9.3 / 10",
        },
        {
          itemContent: "9.0 / 10",
        },
        {
          itemContent: "8.6 / 10",
        },
        {
          itemContent: "8.3 / 10",
        },
        {
          itemContent: "9.1 / 10",
        },
      ],
    },
    {
      title: "Ease of setup",
      content: [
        {
          itemContent: "9.2 / 10",
        },
        {
          itemContent: "8.6 / 10",
        },
        {
          itemContent: "8.3 / 10",
        },
        {
          itemContent: "7.5 / 10",
        },
        {
          itemContent: "7.0 / 10",
        },
      ],
    },
    {
      title: "Ease of admin",
      content: [
        {
          itemContent: "9.3 / 10",
        },
        {
          itemContent: "8.8 / 10",
        },
        {
          itemContent: "8.7 / 10",
        },
        {
          itemContent: "7.9 / 10",
        },
        {
          itemContent: "8.7 / 10",
        },
      ],
    },
    {
      title: "Quality of support",
      content: [
        {
          itemContent: "9.4 / 10",
        },
        {
          itemContent: "8.7 / 10",
        },
        {
          itemContent: "8.9 / 10",
        },
        {
          itemContent: "8.0 / 10",
        },
        {
          itemContent: "9.2 / 10",
        },
      ],
    },
    {
      title: "Ease of doing business with",
      content: [
        {
          itemContent: "9.5 / 10",
        },
        {
          itemContent: "8.7 / 10",
        },
        {
          itemContent: "9.2 / 10",
        },
        {
          itemContent: "7.4 / 10",
        },
        {
          itemContent: "8.9 / 10",
        },
      ],
    },
    {
      title: "Meets requirements",
      content: [
        {
          itemContent: "9.4 / 10",
        },
        {
          itemContent: "9.0 / 10",
        },
        {
          itemContent: "9.0 / 10",
        },
        {
          itemContent: "7.9 / 10",
        },
        {
          itemContent: "9.3 / 10",
        },
      ],
    },
    {
      title: "Product direction",
      subtitle: "% POSITIVE",
      content: [
        {
          itemContent: "9.0 / 10",
        },
        {
          itemContent: "9.2 / 10",
        },
        {
          itemContent: "8.2 / 10",
        },
        {
          itemContent: "6.2 / 10",
        },
        {
          itemContent: "9.5 / 10",
        },
      ],
    },
  ],
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      text: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const comparisonTableContent2 = {
  title: "The best value recurring payments solution",
  description:
    "With great reviews, you might expect Billsby to be unaffordable. Not so fast - Billsby is the best value of all the major recurring billing platforms with our Core plan starting at $35 a month, including $15,000 per month in transaction value",
  heading: [
    {
      logo: "billsby_logo_blue.png",
      title: "Billsby",
    },
    {
      logo: require("../images/chargebee.svg"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/chargify.svg"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/recurly.png"),
      title: "billwerk",
      svgLogo: true,
    },
    {
      logo: require("../images/stripe-billing.png"),
      title: "billwerk",
      svgLogo: true,
    },
  ],
  body: [
    {
      title: "Monthly fee",
      subtitle: "BASE PLAN",
      content: [
        {
          itemContent: "From $35",
        },
        {
          itemContent: "From $99",
        },
        {
          itemContent: "$599",
        },
        {
          itemContent: "From $149",
        },
        {
          itemContent: "$0",
        },
      ],
    },
    {
      title: "Overage transaction fee",
      content: [
        {
          itemContent: "0.4%",
        },
        {
          itemContent: "Up to 0.5%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.5%",
        },
      ],
    },
  ],
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      text: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const singleContent3 = {
  title: "Explore away, there's no credit card required",
  text:
    "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
    },
  ],
}

const badgeData = {
  section: "compared",
  link: false,
  FallBadges: [
    {
      img: require("../images/best-relationship-total-2022.svg"),
      altText: "best-relationship-total-2022",
    },
    {
      img: require("../images/most-implementable-winter-2022.svg"),
      altText: "most-implementable-winter-2022",
    },
    {
      img: require("../images/momentum-leader-winter-2022.svg"),
      altText: "momentum-leader-winter-2022",
    },
    {
      img: require("../images/high-performer-winter-2022.svg"),
      altText: "high-performer-winter-2022",
    },
    {
      img: require("../images/best-usability-winter-2022.svg"),
      altText: "best-usability-winter-2022",
    },
    {
      img: require("../images/fastest-implementation-fall.svg"),
      altText: "fastest-implementation-fall",
    },
    {
      img: require("../images/best-meets-requirements-winter-2022.svg"),
      altText: "best-meets-requirements-winter-2022",
    },
    {
      img: require("../images/best-est-roi-winter.svg"),
      altText: "best-est-roi-winter",
    },
  ],
  SmallBussinessBadge: [
    {
      img: require("../images/best-relationship-small-business-2022.svg"),
      altText: "best-relationship-small-business-2022",
    },
    {
      img: require("../images/best-usability-small-business-2022.svg"),
      altText: "best-usability-small-business-2022",
    },
    {
      img: require("../images/easiest-setup.svg"),
      altText: "easiest-setup",
    },
    {
      img: require("../images/best-est-roi-small-business-2021.svg"),
      altText: "best-est-roi-small-business-2021",
    },
    {
      img: require("../images/high-performer-small-business-2022.svg"),
      altText: "high-performer-small-business-2022",
    },
    {
      img: require("../images/users-most-likely-to-recommend-2021.svg"),
      altText: "users-most-likely-to-recommend-2021",
    },
    {
      img: require("../images/fastest-implementation-2021.svg"),
      altText: "fastest-implementation-2021",
    },
    {
      img: require("../images/best-meets-requirements-small-business-2022.svg"),
      altText: "best-meets-requirements-small-business-2022",
    },
    {
      img: require("../images/easiest-admin-2021.svg"),
      altText: "easiest-admin-2021",
    },
    {
      img: require("../images/easiest-to-use.svg"),
      altText: "easiest-to-use",
    },
  ],
}

const Compared = () => {
  // const dateToFormat = new Date();

  return (
    <Layout>
      <SEO
        title="Compare subscription billing software | Billsby"
        description="The highest-rated, affordable, and customizable subscription billing software with reporting insights and customer retention tools to reduce churn | Billsby"
        url="https://www.billsby.com/compared"
      />

      <Helmet>
        <script type="text/javascript">
          {`
              {
                (function () { var on = window.addEventListener ?
                  function (m,c) { window.addEventListener(m,c,false); } : function (m,c) {
                  window.attachEvent('on'+m,c); } , r = function (e) { if (e.origin !== "https://my.g2.com") { return; }
                  var d = document.getElementById('g2-crowd-widget-testimonial-16576'); if(d){d.style.height =
                  e.data}; r = function () {}; } ; on('message', r); }()); 
              }
              `}
        </script>
      </Helmet>

      <div className="compared">
        <Banner content={bannerContent} />
        <BadgeContent content={badgeData} />
        <ComparisonTable content={comparisonTableContent} />
        <p className="text">
          Data from G2 last updated 26th October 2021 -{" "}
          <a
            href="https://www.g2.com/categories/subscription-billing?utf8=%E2%9C%93&order=top_shelf"
            rel="noopener noreferrer"
            target="_blank"
          >
            View subscription billing on G2 for latest data
          </a>
        </p>

        <ComparisonTable content={comparisonTableContent2} />
        <div className="review-holder">
          <div className="container">
            <p className="title">Our customers explain it best</p>
            <p className="subheading">
              Hear direct from our customers why Billsby is the most powerful,
              customizable and easy to integrate subscription billing software
              used by hundreds of companies worldwide to simplify revenue
              operations.
            </p>
            <iframe
              frameborder="0"
              height="100%"
              id="g2-crowd-widget-testimonial-16576"
              scrolling="no"
              src="https://www.g2.com/products/billsby/testimonials/16576.embed"
              width="100%"
              title="testimonials"
            ></iframe>
            {/* <a href="https://www.g2.com/products/billsby/reviews?utm_campaign=testimonials_embed&amp;ut
              m_medium=testimonials&amp;utm_source=Billsby">Billsby reviews sourced by G2</a> */}
          </div>
        </div>
        {/* <SingleContent content={singleContent3} /> */}
        <FreeTrial content={trialContent}/>
      </div>
    </Layout>
  )
}

export default Compared
